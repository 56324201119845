export const OperationNames = {
	USER_LOGIN: 'user_login',
};

export const SpanNames = {
	LOGIN_ATTEMPT: 'login_attempt',
	LOGIN_AUTHENTICATED: 'login_authenticated',
	LOGIN_REDIRECT: 'login_redirect',
	FETCH_SUBSCRIPTION: 'fetch_subscription',
};

export const SpanCustomAttributesNames = {
	LOGIN_EVENT: 'login.event',
};

export const SpanCustomAttributesValues = {
	LOGIN_REDIRECT_INTERNAL: 'redirect: internal',
	LOGIN_REDIRECT_NO_SUBSCRIPTION: 'redirect: no subscription',
	LOGIN_REDIRECT_BEDROCK: 'redirect: Bedrock',
};
