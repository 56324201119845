import React from 'react';
import Footer from '../footer/Footer';
import cx from 'classnames';
import './Layout.scss';

export default ({
	hideFooter,
	children,
	fullWidthFooter = false,
	tvGemistFinalizePage,
	hasFixedAction,
}) => {
	return (
		<div className="layout">
			<div
				className={cx('app-content', {
					['app-content-tvgemist']: tvGemistFinalizePage,
					['app-content-fixed-button']: hasFixedAction,
				})}
			>
				{children}
				<div className="layout__push" />
			</div>
			<Footer
				hideFooter={hideFooter}
				fullWidthFooter={fullWidthFooter}
				hasFixedAction={hasFixedAction}
			/>
		</div>
	);
};
