import { initiateErrorTracking } from '../components/analytics/Snowplow';

export const ERROR_TYPE = {
	REPORT_ERROR: 'reportError',
	REPORT_CUSTOM_ERROR: 'reportCustomError',
	MARK_AS_ERROR_PAGE: 'markAsErrorPage',
};

const execute = async (method, ...args) => {
	if (__DEBUG__) {
		// eslint-disable-next-line no-console
		console.error('Report error:', ...args);
	}

	const reportCustomErrorOtel = require('@rtl_nl/rtl-otel-js-web').captureError;

	switch (method) {
		case ERROR_TYPE.REPORT_ERROR: {
			await initiateErrorTracking({ message: ERROR_TYPE.REPORT_ERROR, error: { ...args } });
			reportCustomErrorOtel(ERROR_TYPE.REPORT_ERROR, ...args);
			break;
		}
		case ERROR_TYPE.REPORT_CUSTOM_ERROR: {
			const { name, message, error } = { ...args }[0];

			if (typeof error == 'string') {
				await initiateErrorTracking({ message: name, error: { error } });
				reportCustomErrorOtel(name, { message, error });
			}

			if (typeof error == 'object') {
				await initiateErrorTracking({ message: name, error: { ...error } });
				reportCustomErrorOtel(name, { message, error: { ...error } });
			}

			break;
		}
		case ERROR_TYPE.MARK_AS_ERROR_PAGE: {
			await initiateErrorTracking({ message: ERROR_TYPE.MARK_AS_ERROR_PAGE, error: { ...args } });
			reportCustomErrorOtel(ERROR_TYPE.MARK_AS_ERROR_PAGE, ...args);
			break;
		}
		default:
			break;
	}
};

export const markAsErrorPage = (...args) => execute(ERROR_TYPE.MARK_AS_ERROR_PAGE, ...args);
export const reportCustomError = (...args) => execute(ERROR_TYPE.REPORT_CUSTOM_ERROR, ...args);
export const reportError = (...args) => execute(ERROR_TYPE.REPORT_ERROR, ...args);
